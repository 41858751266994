import AppFunctions from '../helpers/AppFunctions'
import axios from 'axios'
export default {
    data() {
        return {
            posts: [{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7}],
            categories: '',
            tags: {},
            //b_aipUrl: 'http://localhost:9991/api/Boob_Solutions/getList',
            b_aipUrl: 'api/Boob_Solutions/getList',
            AppFunctions
        }
    },
       //获取表字段信息开始----->
       mounted() {
        this.getsolutions();

    },
    //获取表字段信息结束----<
    methods: {
        async getsolutions() {

            try {
                const response = await this.$http.get(this.b_aipUrl);
                this.posts = response.data;
                //console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        },
        blogSolutionCategories() {
            let cats = this.posts.map(item => {
                    return item.categories;
                }),
                singleCatArray = AppFunctions.flatDeep(cats),
                categories = [];

            singleCatArray.forEach(cat => {
                const obj = {
                    title: cat.trim(),
                    slug: AppFunctions.slugify(cat),
                    count: 1
                }
                const objIndex = AppFunctions.containsObject(obj, categories);
                if (objIndex !== -1) {
                    const prevCount = categories[objIndex].count;
                    categories[objIndex] = {
                        title: cat.trim(),
                        slug: AppFunctions.slugify(cat),
                        count: prevCount + 1
                    }
                } else {
                    categories.push(obj);
                }
            });

            this.categories = categories;
        },
        blogSolutionTags() {
            let tags = this.posts.map(item => {
                    return item.tags;
                }),
                singleTagArray = AppFunctions.flatDeep(tags),
                tagsData = [];

            singleTagArray.forEach(tag => {
                const obj = {
                        title: tag.trim(),
                        slug: AppFunctions.slugify(tag)
                    },
                    objIndex = AppFunctions.containsObject(obj, tagsData);

                if (objIndex !== -1) {
                    tagsData[objIndex] = {
                        title: tag.trim(),
                        slug: AppFunctions.slugify(tag)
                    }
                } else {
                    tagsData.push(obj);
                }
            });

            this.tags = tagsData;
        }
    },
    /* created() {
        this.blogSolutionCategories();
        this.blogSolutionTags();
    } */
}