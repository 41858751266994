<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card" v-for="(content, index) in accordionContent">
                <h2 class="accordion-header card-header" :id="`heading${content.id}`">
                    <button class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#collapse${content.id}`"
                            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                            :aria-controls="`collapse${content.id}`"
                            @click="activeCollapse = index">
                        {{ content.title }}
                        <i class="collapse-icon"/>
                    </button>
                </h2>
                <div :id="`collapse${content.id}`"
                     class="accordion-collapse collapse"
                     :class="{'show': index === 0}"
                     :aria-labelledby="`heading${content.id}`"
                     :data-bs-parent="`#${id}`">
                    <div class="accordion-body card-body">
                        {{ content.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
        props: {
            id: {
                type: String,
                default: 'accordionExample'
            },
            accordionStyleClass: {
                type: String
            },
            accordionContent: {
                type: Array,
                default: function () {
                    return [
                        {
                            id: 'One',
                            title: '提供的服务',
                            description: '我们致力于为您提供最佳解决方案和最合适的产品，包括：生产线的设计、设备的优化与升级、自动化控制系统、数字化管理系统、仪器仪表、备件和服务等'
                        },
                        {
                            id: 'Two',
                            title: '最高性价比的方案',
                            description: '采用最先进的技术，最可靠的方案，实现最低的总体综合成本：降低前期投资、减少服务成本，节省运营投入'
                        },
                        {
                            id: 'Three',
                            title: '实现预期性能和效果',
                            description: '我们的产品和方案是久经考验，经历多次优化和升级，并将持续优化满足市场需求'
                        },
                        {
                            id: 'Four',
                            title: '您的满意，我们的动力',
                            description: '项目的完成只是开始，用心的服务才是永恒，您的持续增长是我们为之奋斗的源泉'
                        },
                    ]
                }
            }
        },
        data() {
            return {
                activeCollapse: 0
            }
        }
    }
</script>