<template>
    <Layout>
        <Breadcrumb title="应用解决方案" current-url="解决方案列表"/>

        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <div class="row mt_dec--30">
                            <div class="col-lg-12">
                                <div class="row row--15">
                                    <div class="col-lg-12 mt--30"
                                         data-aos="slide-up"
                                         data-aos-duration="800"
                                         :data-aos-delay="100 + index"
                                         v-for="(solution, index) in posts"
                                         :key="index">
                                        <BlogPost :solution="solution" blog-post-style="list"/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!--
                    <div class="col-lg-4 mt_md--40 mt_sm--40"> 
                        <BlogSidebar/>
                    </div> !-->
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Icon from '../components/icon/Icon'
    import BlogPost from '../components/blog/BlogSolutionPost'
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import BlogSidebar from '../components/blog/BlogSolutionSidebar'
    import BlogPostMixin from '../mixins/SolutionPostMixin'

    export default {
        name: 'SolutionListSidebarPage',
        components: {BlogSidebar, Icon, BlogPost, Layout, Breadcrumb},
        mixins: [BlogPostMixin]
    }
</script>