<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">

            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <h4 class="title">公司信息</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <router-link to="/about">关于我们</router-link>
                                        </li>
                                        <!-- <li>
                                            <router-link to="/about">公司新闻</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/about">行业资讯</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/about">政策导向</router-link>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <div class="widget-menu-top">
                                    <h4 class="title">解决方案</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li>
                                                <router-link to="/solution-list">升级优化</router-link>
                                            </li>
                                            <li>
                                                <router-link to="/solution-list">全植物基饮品   </router-link>
                                            </li>
                                            <li>
                                                <router-link to="/solution-list">预防性维护</router-link>
                                            </li>
                                            <li>
                                                <router-link to="/solution-list">工厂数字化</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <h4 class="title">行业应用</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <router-link to="/solution-list">预制食品</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/solution-list">乳品</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/solution-list">饮料</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/solution-list">日化产品</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <h4 class="title">产品系列</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <router-link to="/portfolio">粉料输送</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/portfolio">真空混合</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/portfolio">在线除菌添加</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/portfolio">连续标准化混合</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <h4 class="title">用心服务</h4>
                                <div class="inner">
                                    <h6 class="subtitle">30+ 客户项目成功合作体验</h6>
                                    <ul class="social-icon social-default justify-content-start">
                                        <li>
                                            
                                                <img name="qrcode_shgg"/>
                                                <img class="light-icon"
                                                     src="../../../assets/images/logo/qrcode_shgg.png"
                                                     alt="谷光工程">
                                            
                                        </li>
                                        <!-- <li>
                                            <a href="https://www.twitter.com" target="_blank">
                                                <Icon name="twitter"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/" target="_blank">
                                                <Icon name="instagram"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/" target="_blank">
                                                <Icon name="linkedin"/>
                                            </a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter v-if="data.showNewsletter"/>
        </footer>
        <!-- End Footer Area  -->

        <Copyright v-if="data.showCopyright"/> 

        <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import Button from '../../elements/button/Button'
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'
    import Newsletter from './Newsletter'

    export default {
        name: 'Footer',
        components: {Newsletter, Logo, ScrollTop, Icon, Button, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>