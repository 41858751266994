<template>
    <aside class="rwt-sidebar">
        
        <!-- Start Single Widget -->
        <div class="rbt-single-widget widget_categories mt--40">
            <h3 class="title">分类</h3>
            <div class="inner">
                <BlogCategories/>
            </div>
        </div>
        <!-- End Single Widget -->

        <!-- Start Single Widget -->
        <div class="rbt-single-widget widget_recent_entries mt--40">
            <h3 class="title">列表</h3>
            <div class="inner">
                <BlogSidebarPost/>
            </div>
        </div>
        <!-- End Single Widget -->

    </aside>
</template>

<script>
    import Icon from '../icon/Icon'
    import BlogCategories from './BlogSolutionCategories'
    import BlogSidebarPost from './BlogSolutionSidebarPost'

    export default {
        name: 'BlogSidebar',
        components: { BlogSidebarPost, BlogCategories, Icon}
    }
</script>