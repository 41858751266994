<template>
    <form class="contact-form-1" action="" @submit.prevent="submitForm">
        <div class="form-group">
            <input
                type="text"
                id="fullname"
                placeholder="称呼"
                v-model="formData.fullName"
                required
            />
        </div>

        <div class="form-group">
            <input
                type="email"
                id="email"
                placeholder="电子邮箱"
                v-model="formData.email"
                required
            />
        </div>

        <div class="form-group">
            <input
                type="text"
                id="phone"
                placeholder="联系电话"
                v-model="formData.phone"
                required
            />
        </div>


        <div class="form-group">
            <input
                type="text"
                id="subject"
                placeholder="标题"
                v-model="formData.subject"
                required
            />
        </div>

        <div class="form-group">
                <textarea
                    id="message"
                    placeholder="咨询或建议留言"
                    v-model="formData.message"
                    required>
                </textarea>
        </div>

        <div class="form-group">
            <button class="btn-default btn-large">提交</button>
        </div>

        <div class="form-group" v-html="showResult ? result : null"/>
    </form>
</template>

<script>
import axios from 'axios'
    export default {
        name: 'ContactForm',
        data() {
            return {
                submitUrl:'api/Boob_Submitform/addform',
                formData:{
                    fullName:'',
                    email:'',
                    phone:'',
                    subject:'',
                    message:''  
                },
                showResult: false,
                result: `<p class="success-message">您的留言已经提交成功，我们将会在最短的时间内尽快联系到您，请保持电话畅通.</p>`
            }
        },
        methods: {
            async submitForm() {
                try {
                  const response = await this.$http.post(this.submitUrl,{ mainData: this.formData });
                  alert('提交成功！');
                  this.showResult = true;
                  this.formData = {};
                 }catch (error) {
                console.log(error);
                }
            }
        }
    }
</script>