<template>
  <Layout>
    <div class="rn-blog-details-area">
      <div class="post-page-banner rn-section-gapTop">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="content text-center">
                <div class="page-title">
                  <h1 class="theme-gradient" v-html="post.title" />
                </div>
                <ul class="rn-meta-list">
                  <li>
                    <Icon name="user" class="size-20" />
                    <router-link to="#">{{ post.author }}</router-link>
                  </li>
                  <li>
                    <Icon name="calendar" class="size-20" />
                    {{ post.publishedAt }}
                  </li>
                </ul>
                <div class="thumbnail alignwide mt--60">
                  <img
                    class="w-100 radius"
                    :src="getImgUrl(post.img)"
                    :alt="post.title"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-details-content pt--60 rn-section-gapBottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="content">
                <p v-html="post.body" />

                <div class="category-meta">
                  <span class="text">标签:</span>
                  <div
                    class="tagcloud"
                    v-for="(ps, index) in post.tags"
                    :key="index"
                  >
                    <a href="#" v-html="ps" />
                  </div>
                </div>

                <!-- Start Contact Form Area  -->
                <div class="rn-comment-form pt--60">
                  <div class="inner">
                    <div class="section-title">
                      <span class="subtitle">对该方案感兴趣?</span>
                      <h2 class="title">您的建议</h2>
                    </div>
                    <form class="mt--40" action="" @submit.prevent="submitForm">
                      <div class="row">
                        <div class="col-lg-6 col-md-12 col-12">
                          <div class="rnform-group">
                            <input
                              type="text"
                              placeholder="称呼"
                              v-model="formData.fullName"
                              required
                            />
                          </div>
                          <div class="rnform-group">
                            <input
                              type="email"
                              placeholder="E-mail邮箱地址"
                              v-model="formData.email"
                            />
                          </div>
                          <div class="rnform-group">
                            <input
                              type="text"
                              placeholder="手机"
                              v-model="formData.phone"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12">
                          <div class="rnform-group">
                            <textarea
                              placeholder="需求或建议"
                              v-model="formData.message"
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="blog-btn">
                            
                              <button class="btn-default btn-large">
                                提交
                              </button>
                            
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-group"
                        v-html="showResult ? result : null"
                      />
                    </form>
                  </div>
                </div>
                <!-- End Contact Form Area  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Icon from '../components/icon/Icon'
    import axios from 'axios'
    export default {
        name: 'SolutionDetailsPage',
        components: {Icon, Layout, Breadcrumb},
        data() { 
            return {
                id: this.$route.params.id,
                post: {},
                d_aipUrl: 'api/Boob_Solutions/getList',
                submitUrl:'api/Boob_Submitform/addform',
                formData:{
                    fullName:'',
                    email:'',
                    phone:'',
                    subject:'',
                    message:'',
                    subject: '',
                    oppType: this.$route.params.id               
                },
                showResult: false,
                result: `<p class="success-message">您的建议已经提交成功，我们将会在最短的时间内尽快联系到您，请保持电话畅通.</p>`
            }
        },
        methods: {
          async getPost(postId) {
            try {
                const response = await this.$http.get(this.d_aipUrl);
                this.post = response.data.find(item => item.id == postId);
                //console.log(response.data);
            } catch (error) {
                console.log(error);
            }
            },
            async submitForm() {
              this.formData.subject=this.post.title;
              try {
                  const response = await this.$http.post(this.submitUrl,{ mainData: this.formData });
                  this.showResult = true;
                  this.formData = {};
                 }catch (error) {
                console.log(error);
            }
            }
        },
        mounted() {
            this.getPost(this.id);
        }
    }
</script>