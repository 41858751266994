<template>
    <Layout header-class="header-left-align" nav-alignment="right" :header-transparency="true" :show-top-header="false" buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                         :style="{'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})`}">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="order-2 order-lg-1 col-lg-8">
                                    <div class="inner text-start">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description" v-html="slider.description"/>
                                       <!--  <div class="button-group mt--30">
                                            <router-link :to="`/solution-details/${slider.id}`">
                                                <a class="btn-default btn-small round">
                                                更多细节
                                                </a>
                                            </router-link>
                                            
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        

        <Separator/>

        <!-- Start care Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="CARE ABOUT"
                            title="您最关注的问题有哪些？"
                            description=""
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceThree
                    service-style="gallery-style"
                    text-align="left"
                />
            </div>
        </div>
        <!-- End care Area -->

        <!-- Start Call To Action Area -->
        <div class="rwt-callto-action-area" >
            <div class="wrapper" >
                <CallToAction
                    :style-type="5"
                    title="数字化的转型时代，不可逆的行业趋势，您将何去何从？"
                    subtitle="可视化生产与设备数据管理，引领数字化时代的潮流"
                    btn-text="阅读更多"
                />
            </div>
        </div>
        <!-- End Call To Action Area -->



        <Separator/>

        <!-- Start solution Area -->
        <div class="rwt-testimonial-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="SOLUTIONS"
                            title="应用解决方案"
                            description="高性价比是目标，解决问题才是根本"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Testimonial :testimonial="posts" :testimonial-style="5" data-aos="fade-up"/>
                 <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                        <router-link class="btn-default" to="/solution-list">
                            <span>更多</span>
                            <Icon name="arrow-right" size="20" icon-class="icon"/>
                        </router-link>
                 </div>
            </div>
        </div>
        <!-- End solution Area -->

        <Separator/>

        <!-- Start Products Area -->
        <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Latest Products"
                            title="多样化产品系列"
                            description="匹配您的现在与将来的需求."
                            data-aos="fade-up"
                        />
                    </div>
                    
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100 + index"
                         v-for="(portfolio, index) in portfolioItems"
                         :key="index"
                         v-if="index < 3">
                        <BlogPost :portfolio="portfolio"/> 
                    </div>
                </div>
                <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                        <router-link class="btn-default" to="/portfolio">
                            <span>更多</span>
                            <Icon name="arrow-right" size="20" icon-class="icon"/>
                        </router-link>
                 </div>
            </div>
        </div>
        <!-- End Products Area -->
        <Separator/>

        <!-- Start About Area -->
        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="../../assets/images/about/about-1.png" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h2 class="title theme-gradient" data-aos="fade-up" data-aos-delay="280">
                                    用心，成就未来 <br>
                                    <vue-typed-js :strings="['专业服务', '灵活敏捷', '实事求是']"
                                                  :loop="true"
                                                  :type-speed="160"
                                                  :start-delay="1000"
                                                  :back-speed="100">
                                        <b class="is-visible theme-gradient typing">专业服务</b>
                                    </vue-typed-js>
                                </h2>
                                <p data-aos="fade-up" data-aos-delay="310">
                                    公司正处于蓬勃发展阶段，坐落于上海新华文化创新科技园。致力于食品行业加工解决方案，生产线性能升级与优化，采用自主开发数字化生产管理系统服务于客户群体
                                </p>
                                <p data-aos="fade-up" data-aos-delay="340">
                                    提供全方位解决方案，覆盖整线设计、优化升级、单体设备、自动化系统与数字化升级、备件与服务。
                                </p>
                                <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                                    <router-link class="btn-default" to="/about">
                                        <span>关于我们</span>
                                        <Icon name="arrow-right" size="20" icon-class="icon"/>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->

        
        <Separator/>

        <!-- Start Contact Area -->
        <div class="rn-company-mission-are rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-6">
                        <div class="mission-title">
                            <h2 class="title theme-gradient" data-aos="fade-up">
                                 如何更好地帮到您<br>
                                <vue-typed-js :strings="['敬畏创新.','理解需求.', '方案报价.', '及时交付.']"
                                              :loop="true"
                                              :type-speed="80"
                                              :start-delay="1000"
                                              :back-speed="50">
                                    <b class="is-visible typing">敬畏创新.</b>
                                </vue-typed-js>
                            </h2>
                            <p data-aos="fade-up">
                                我们的销售团队非常乐意为您解答任何有关销售或产品的疑问， 我们尊重每一个改变的想法，敬畏探索与创新精神
                            </p>
                            <p data-aos="fade-up">
                                如果能够帮到您，将是我们极大的荣幸。请从下面的链接留下您的想法，以便我们更好地为您提供帮助
                            </p>
                            <div class="read-more-btn mt--50" data-aos="fade-up">
                                <router-link class="btn-default btn-icon"
                                             to="contact">
                                    联系我们
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt_md--30 mt_sm--30">
                        <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- Start Contact Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Separator from '../../components/elements/separator/Separator'
    import VueSlickCarousel from 'vue-slick-carousel'
    import Icon from '../../components/icon/Icon'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceThree from '../../components/elements/service/ServiceThree'
    import Team from '../../components/elements/team/Team'
    import BlogPost from '../../components/blog/BlogPortfolioPost '
    import BlogPostMixin from '../../mixins/PortfolioItemMixin'
    import SolutionPostMixin from '../../mixins/SolutionPostMixin'
    import CallToAction from '../../components/elements/callToAction/CallToAction'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import Accordion from '../../components/elements/accordion/Accordion'

    export default {
        name: 'BusinessConsulting2',
        components: {
            Accordion,
            Testimonial,
            CallToAction,
            BlogPost,
            VueSlickCarousel,
            Team,
            ServiceThree,
            SectionTitle,
            Icon,
            Separator,
            Layout
        },
        mixins: [BlogPostMixin,SolutionPostMixin],
        data() {
            return {
               

                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    autoplay: true,         //自动播放
                    autoplaySpeed: 4000,  //翻页速度
                    speed: 2000,           //切换速度
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        id:1,
                        image: 'slidedata-image-1',
                        title: '数字布控全局</br>科技引领未来',
                        description: '智能制造时代数字化升级'
                    },
                    {
                        id:2,
                        image: 'slidedata-image-2',
                        title: '匠心服务</br> 实事求是',
                        description: '预测未来最好的方法，就是亲手去实现它'
                    },
                    {
                        id:3,
                        image: 'slidedata-image-3',
                        title: '您的满意</br>我们的动力',
                        description: '以客户为中心，与客户共创双赢'
                    },
                ]
            }
        }
    }
</script>