<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <iframe src="https://www.amap.com/place/B00157HZ5V" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
    export default {
        name: 'GoogleMap'
    }
</script> 