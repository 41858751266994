<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div data-aos="fade-up" data-aos-delay="70">
                <div :class="`service ${serviceStyle} ${checkTextAlign}`">
                    <div class="inner">
                        <router-link class="image" :to="''">
                            <img :src="service.image" alt="Service Images" />
                        </router-link>
                        <div class="content">
                            <h4 class="title">
                                <router-link to="" v-html="service.title"/>
                            </h4>
                            <p class="description b1 color-gray mb--0" v-html="service.description"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceThree',
        props: {
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        image: require('@/assets/images/service/servicelist-01.png'),
                        title: '高可靠和稳定性',
                        description: '性能保障，高质量保证'
                    },
                    {
                        image: require('@/assets/images/service/servicelist-02.png'),
                        title: '最佳投资回报',
                        description: '最低投入，最佳实践与产出'
                    },
                    {
                        image: require('@/assets/images/service/servicelist-03.png'),
                        title: '快速灵活响应',
                        description: '丰富的经验积累，快速达到预期'
                    }
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>