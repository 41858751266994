<template>
    <div class="about-area about-style-4 rn-section-gap">
        <div class="container">
            <div class="row row--40 align-items-center">
                <div class="col-lg-6">
                    <Video :video-data="videoData" column="col-12"/>
                </div>
                <div class="col-lg-6 mt_md--40 mt_sm--40">
                    <div class="content">
                        <div class="inner">
                            <h3 class="title">专业服务于 <br/> 食品行业 <strong>解决方案</strong></h3>
                            <ul class="feature-list">
                                <li>
                                    <Icon name="check" size="20" icon-class="icon"/>
                                    <div class="title-wrapper">
                                        <h4 class="title">简介</h4>
                                        <p class="text">
                                            公司成立于2020年，坐落于上海新华科技园。致力于食品行业加工解决方案，生产线性能升级与优化，采用自主开发数字化生产管理系统服务于客户群体
                                        </p>
                                    </div>
                                </li>
                                 <li>
                                    <Icon name="check" size="20" icon-class="icon"/>
                                    <div class="title-wrapper">
                                        <h4 class="title">愿景</h4>
                                        <p class="">
                                            让先进的解决方案惠及所有客户群体
                                        </p>
                                    </div>
                                </li>
                                
                                <li>
                                    <Icon name="check" size="20" icon-class="icon"/>
                                    <div class="title-wrapper">
                                        <h4 class="title">使命</h4>
                                        <p class="">
                                            诚信，铸就品质<br/>
                                            创新，引领未来户<br/>
                                            实事求是，以诚为本
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Video from "../video/Video";

    export default {
        name: 'AboutFour',
        components: {Video, Icon},
        props: {
            image: {}
        },
        data() {
            return {
                videoData: [
                    {
                        thumb: this.image,
                        src: 'https://v.youku.com/v_show/id_XMjI4NzA5NTU1M',
                    }
                ]
            }
        }
    }
</script>