<template>
    <ul class="mainmenu">
        <li><router-link to="/home">首页</router-link></li>
        <!-- 
         <li class="has-droupdown has-menu-child-item">
            <a href="#">
                Demo
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <ul class="submenu">
                <li><router-link to="/business-consulting">Business Consulting</router-link></li>
                <li><router-link to="/business-consulting-2">Business Consulting 02</router-link></li>
                <li><router-link to="/corporate">Corporate</router-link></li>
                <li><router-link to="/business">Business</router-link></li>
                <li><router-link to="/digital-agency">Digital Agency</router-link></li>
                <li><router-link to="/finance">Finance</router-link></li>
                <li><router-link to="/company">Company</router-link></li>
                <li><router-link to="/marketing-agency">Marketing Agency</router-link></li>
                <li><router-link to="/travel-agency">Travel Agency</router-link></li>
                <li><router-link to="/consulting">Consulting</router-link></li>
                <li><router-link to="/seo-agency">SEO Agency</router-link></li>
                <li><router-link to="/personal-portfolio">Personal Portfolio</router-link></li>
                <li><router-link to="/event-conference">Event Conference</router-link></li>
                <li><router-link to="/creative-portfolio">Creative Portfolio</router-link></li>
                <li><router-link to="/freelancer">Freelancer</router-link></li>
                <li><router-link to="/international-consulting">International Consulting</router-link></li>
                <li><router-link to="/startup">Startup</router-link></li>
                <li><router-link to="/web-agency">Web Agency</router-link></li>
            </ul>
        </li>
        -->
        <li><router-link to="/solution-list">解决方案</router-link></li>      
        <li><router-link to="/portfolio">产品系列</router-link></li>
        <li><router-link to="/about">关于我们</router-link></li>
        <li><router-link to="/contact">联系我们</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>