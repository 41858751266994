<template>
    <Layout>
        <div class="rwt-portfolio-details rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <div class="inner">
                            <div class="details-list">
                                <div class="thumbnail alignwide">
                                    <img class="radius w-100" :src="getImgUrl(portfolio.largeImage)" alt="Corporate Image"/>
                                </div>

                                <div class="row mt--40 row--30">
                                    <div class="col-lg-6">
                                        <div class="content-left">
                                            <h4 class="title">{{ portfolio.title }}</h4>
                                            <div class="single-list-wrapper">
                                                <div class="single-list">
                                                    <label>更新日期:</label>
                                                    <span>{{ portfolio.date }}</span>
                                                </div>
                                                <div class="single-list">
                                                    <label>应用范围:</label>
                                                    <span>{{ portfolio.client }}</span>
                                                </div>
                                                <div class="single-list">
                                                    <label>类别:</label>
                                                    <span>{{ portfolio.category }}</span>
                                                </div>
                                            </div>
                                            <div class="view-btn mt--50">
                                                <a class="btn-default btn-large round" :href="portfolio.buttonLink">
                                                    {{ portfolio.buttonText}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mt_md--30 mt_sm--30">
                                        <div class="content-right">
                                            <h5 class="subtitle">{{ portfolio.subtitle }}</h5>
                                            <div class="description" v-html="portfolio.body"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="portfolio-gallery mt--40">
                                <div class="gallery mt--30">
                                    <div class="thumbnail">
                                        <img class="radius w-100" :src="getImgUrl(portfolio.galleryimageOne)"
                                             alt="Corporate Image"/>
                                    </div>
                                </div>
                                <div class="gallery mt--30">
                                    <div class="thumbnail">
                                        <img class="radius w-100" :src="getImgUrl(portfolio.galleryimageTwo)"
                                             alt="Corporate Image"/>
                                    </div>
                                </div>
                                <!-- <div class="gallery mt--30">
                                    <div class="thumbnail">
                                        <img class="radius w-100" :src="'/api/'+portfolio.galleryimageThree"
                                             alt="Corporate Image"/>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    //import PortfolioItemMixin from '../mixins/PortfolioItemMixin'
    import axios from 'axios'
    export default {
        name: 'PortfolioDetailsPage',
        components: {Layout},
       // mixins: [PortfolioItemMixin],
        data() {
            return {
                id: this.$route.params.id,
                portfolio: {},
                c_aipUrl: 'api/Boob_Portfolios/getList',
            }
        },
        methods: {
            async getPortfolio(portfolioId) {

                try {
                const response = await this.$http.get(this.c_aipUrl);
                this.portfolio = response.data.find(item => item.id == portfolioId);
                //console.log(this.portfolio);
            } catch (error) {
                console.log(error);
            }

                
            }
        },
        created() {
            this.getPortfolio(this.id);
        }
    }
</script>