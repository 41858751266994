export default {
    data() {
        return {
           portfolioItems:  [],
           //a_aipUrl: 'http://localhost:9991/api/Boob_Portfolios/getList',
           a_aipUrl: 'api/Boob_Portfolios/getList',
        }
    },
    //获取表字段信息开始----->
    mounted() {
        this.getportfolios();
    },
    //获取表字段信息结束----< 
    methods: {
        async getportfolios() {
            try {
                const response = await this.$http.get(this.a_aipUrl);
                this.portfolioItems = response.data;
                //console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        }
    }
}